const baseUrl = process.env.REACT_APP_NEXABLU_API_URL;
const apiKey = process.env.REACT_APP_NEXABLU_API_KEY;

export function postEmail(email) {
  return fetch(baseUrl, {
    method: "POST", // POST for create, PUT to update when id already exists.
    headers: { "content-type": "raw", "x-api-key": apiKey },
    body: JSON.stringify(email),
  })
    .then(handleResponse)
    .catch(handleError);
}

export async function handleResponse(response) {
  if (response.ok) return response.json();
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = await response.text();
    const errorJson = JSON.parse(error)
    throw new Error(errorJson.moreInfos?errorJson.moreInfos:error);
  }
  if (response.status === 500) {
    return response.json();
  }
  throw new Error("Network response was not ok.");
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error("API call failed. " + error);
  throw error;
}
