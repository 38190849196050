import React, { useState, useEffect, useRef } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import "../index.css";
import IconButton from "@material-ui/core/IconButton";
import Menu from "./Menu";

function ToolBar(props) {
  const [headerVisible, setHeaderVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", scrollCheckHeader);
  }, []);

  function scrollCheckHeader() {
    if (window.pageYOffset > 50) {
      setHeaderVisible(true);
    } else {
      setHeaderVisible(false);
    }
  }

  const headerRef = useRef(null);

  const scrollToTop = () => {
    headerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div ref={headerRef}></div>
      <Menu
        open={menuVisible}
        onClose={() => setMenuVisible(false)}
        onContact={props.onContact}
        onChange={scrollToTop}
      />
      <CSSTransitionGroup
        transitionName="header"
        transitionEnterTimeout={200}
        transitionLeaveTimeout={200}
      >
        {headerVisible && (
          <div className="header" id="mainHeader">
            <img
              src={require("../assets/images/Logo-Nexablu-Blue.svg")}
              alt="logo"
              id="logo"
              style={{ paddingTop: 20, height: 36 }}
            />
            <IconButton
              id="iconButton"
              aria-label="delete"
              onClick={() => setMenuVisible(true)}
              style={{
                float: "right",
                height: 80,
                width: 80,
              }}
            >
              <img
                src={require("../assets/images/Menu-burger-blue.svg")}
                alt="Open Menu Button"
              />
            </IconButton>
          </div>
        )}
      </CSSTransitionGroup>
      <div
        className="display-top-left"
        style={{ zIndex: 1, width: "100%", boxSizing: "border-box" }}
      >
        <img
          src={require("../assets/images/Logo-Nexablu-White.svg")}
          alt="logo"
          id="logo"
          style={{ paddingTop: 20, height: 36 }}
        />
        <IconButton
          id="iconButton"
          aria-label="delete"
          onClick={() => setMenuVisible(true)}
          style={{
            float: "right",
            height: 80,
            width: 80,
          }}
        >
          <img
            src={require("../assets/images/Menu-burger-white.svg")}
            alt="Open Menu Button"
          />
        </IconButton>
      </div>
    </>
  );
}

export default ToolBar;
