import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  buttonStyle: {
    webkitFontSmoothing: "antialiased",
    position: "relative",
    display: "inline-block",
    margin: "15px 0",
    textAlign: "center",
    fontSize: "12px",
    textDecoration: "none",
    outline: "none",
    whiteSpace: "nowrap",
    cursor: (props) => (props.disabled ? "default" : "pointer"),
    fontFamily: "Gilroy B",
    color: "#4d8fcc",
    color: (props) => (props.disabled ? "rgba(0, 0, 0, 0.26);" : props.color),
    letterSpacing: "0.05em",
    padding: "12px 10px 10px",
    textShadow: "none",
    fontWeight: 900,
    border: "none",
    background: "none",
    width: (props) => props.width,
    "&::after": {
      position: "absolute",
      top: "100%",
      left: 0,
      width: "100%",
      height: "2px",
      content: "''",
      transform: "scaleX(1)",
      background: "rgb(33, 45, 116)",
      background: (props) => props.color,
      opacity: 0,
      transition: "top 0.3s, opacity 0.3s, transform 0.3s",
    },
    "&::before": {
      position: "absolute",
      top: "100%",
      left: 0,
      width: "100%",
      height: "2px",
      content: "''''",
      transition: "transform 0.3s",
      transform: "scaleX(1)",
      background: "rgb(33, 45, 116)",
      background: (props) =>
        props.disabled ? "rgba(0, 0, 0, 0.26);" : props.color,
    },
    "&:hover": {
      "&::before": {
        transform: (props) => (props.disabled ? "" : "scaleX(1.15)"),
      },
      "&::after": {
        top: 0,
        opacity: (props) => (props.disabled ? "" : 1),
        transform: "scaleX(1.15)",
      },
    },
  },
});

const StyledCustomButton = ({
  children,
  disabled,
  onClick,
  color,
  width,
  ...props
}) => {
  const { buttonStyle } = useStyles({ disabled, color, width });
  return (
    <button
      className={buttonStyle}
      onClick={disabled ? null : onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default StyledCustomButton;
