import React, { useState, useEffect, useRef } from "react";
import { CSSTransitionGroup } from "react-transition-group";
import "../index.css";
import IconButton from "@material-ui/core/IconButton";
import Menu from "./Menu";
import "../index.css";

function Footer(props) {
  return (
    <div className="source" ref={props.ref}>
      <div className="info wide-3" style={{ fontFamily: "Source Sans Pro" }}>
        <p className="m-wide-1">
          <img
            src={require("../assets/images/Picto-Telephone.svg")}
            className="picto"
            alt="Telephone"
          />{" "}
          +1 (514) 448-0601
        </p>
        <p className="m-wide-1">
          <img
            src={require("../assets/images/Picto-Mail.svg")}
            className="picto"
            alt="Mail"
          />{" "}
          info@nexablu.com
        </p>
      </div>
      <div className="copyright wide-3">
        <p>© 2020 nexablu. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
