import React, { useRef } from "react";
import Main from "./Main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import About from "./About";
import Services from "./Services";
import Footer from "./Footer";
import ToolBar from "./Toolbar";
import EmailForm from "./EmailForm";

function App(props) {
  const footerRef = useRef(null);
  const headerRef = useRef(null);

  const scrollToBottom = () => {
    footerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToTop = () => {
    headerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Router>
      <div ref={headerRef}></div>
      <ToolBar onContact={scrollToBottom} />
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/">
          <Main onContact={scrollToBottom} onChange={scrollToTop} />
        </Route>
      </Switch>
      <div ref={footerRef}></div>
      <EmailForm />
      <Footer />
    </Router>
  );
}

export default App;
