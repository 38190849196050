import React from "react";
import "../index.css";
import { CSSTransitionGroup } from "react-transition-group";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";

function Menu(props) {
  return (
    <>
      <CSSTransitionGroup
        transitionName="menu"
        transitionEnterTimeout={300}
        transitionLeaveTimeout={200}
      >
        {props.open && (
          <div
            className="menu"
            style={{
              width: "100%",
              position: "fixed",
              zIndex: 200,
              background: "#267ABF",
              overflow: "hidden",
            }}
          >
            <div style={{ height: "100vh", paddingTop: 10 }}>
              <img
                style={{ height: 36 }}
                src={require("../assets/images/Logo-Nexablu-White.svg")}
                alt="logo"
                id="menuLogo"
              />
              <IconButton
                id="iconButton"
                aria-label="delete"
                onClick={() => props.onClose()}
                style={{
                  float: "right",
                  height: 80,
                  width: 80,
                }}
              >
                <img
                  src={require("../assets/images/Menu-close.svg")}
                  alt="Close Menu Button"
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </IconButton>
              <div
                style={{
                  margin: 0,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CssLink
                  to="/home"
                  onClick={() => {
                    props.onClose();
                    props.onChange();
                  }}
                >
                  Home
                </CssLink>
                <CssLink
                  to="/about"
                  onClick={() => {
                    props.onClose();
                    props.onChange();
                  }}
                >
                  About us
                </CssLink>
                <CssLink
                  to="/services"
                  onClick={() => {
                    props.onClose();
                    props.onChange();
                  }}
                >
                  Services
                </CssLink>
                <CssLink
                  onClick={() => {
                    props.onClose();
                    props.onContact();
                  }}
                >
                  Contact us
                </CssLink>
              </div>
            </div>
          </div>
        )}
      </CSSTransitionGroup>
    </>
  );
}

function CssLink({ children, ...other }) {
  return (
    <div style={{ textAlign: "center", height: 58, fontSize: 33, padding: 9 }}>
      <Link {...other} style={{ color: "white", textDecoration: "none" }}>
        {children}
      </Link>
    </div>
  );
}

export default Menu;
