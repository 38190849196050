import React from "react";
import TextField from "@material-ui/core/TextField";
import CustomCheckbox from "./CustomCheckbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomButton from "./CustomButton";
import { postEmail } from "../api/emailApi";
import { CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "../index.css";

function EmailForm(props) {
  const [state, setState] = React.useState({
    name: "",
    email: "",
    message: "",
    checked: false,
    special: "", //bot detection
  });
  const [errors, setErrors] = React.useState({});
  const [networkState, setNetworkState] = React.useState({
    submitting: false,
    submitted: false,
    networkError: "",
  });

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]:
        event.target.name === "checked"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleBlur = (event) => {
    fieldIsValid(event);
  };

  function fieldIsValid(event) {
    const name = event.target.name;
    const value = event.target.value;
    var newError = "";

    if (name === "name") {
      if (!value) newError = "Name is required.";
    }
    if (name === "email") {
      if (!value) newError = "Email is required.";
      else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value))
        newError = "Invalid email.";
    }

    setErrors({ ...errors, [name]: newError });
  }

  function submitForm() {
    if (state.special) {
      //Checking if special has been set
      return true;
    }

    if (
      !(errors.email || errors.name || !(state.name && state.email)
        ? true
        : false)
    ) {
      setNetworkState({
        ...networkState,
        submitting: true,
      });

      var submit = {
        name: state.name,
        email: state.email,
        checked: state.checked,
      };

      if (state.message) submit.message = state.message;
      postEmail(submit).then(handleResponse).catch(handleError);
    }
  }

  async function handleResponse(response) {
    setNetworkState({
      ...networkState,
      submitting: false,
      submitted: true,
    });
    //console.log(response);
  }

  async function handleError(response) {
    //console.log(response);
    setNetworkState({
      ...networkState,
      submitting: false,
      networkError: response.toString(),
    });
  }

  return (
    <div
      className="padded form"
      id="form"
      style={{ backgroundColor: "#1969AC" }}
    >
      {networkState.submitting ? (
        <div className="maxed text-white" style={{ textAlign: "center" }}>
          <h1>Submitting...</h1>
          <CircularProgress
            style={{ width: "150px", height: "150px", color: "white" }}
          />
        </div>
      ) : networkState.submitted ? (
        <div className="maxed text-white form-info" style={{ paddingLeft: 15 }}>
          <h1 style={{ fontSize: 45, marginBottom: 55 }}>Submitted</h1>
          <p
            className="form-desc"
            style={{
              fontSize: 19,
              lineHeight: 1.8,
              fontFamily: "Source Sans Pro",
              marginBottom: 55,
            }}
          >
            Thank you for contacting us.
          </p>
        </div>
      ) : (
        <div>
          <div
            className="maxed text-white form-info"
            style={{ paddingLeft: 15 }}
          >
            <h1 style={{ fontSize: 45, marginBottom: 55 }}>
              Get in touch with us
            </h1>
            <p
              className="form-desc"
              style={{
                fontSize: 19,
                lineHeight: 1.8,
                fontFamily: "Source Sans Pro",
                marginBottom: 55,
              }}
            >
              We would be glad to hear from you, so if you have any questions or
              inquiries about us or how we can help, please drop us a line using
              the following form.
              <br />
              We will get back to you promptly.
            </p>
          </div>
          <div id="mc_embed_signup" className="maxed text-white">
            <h3 style={{ color: "#ffb1ab", paddingLeft: "10px" }}>
              {networkState.networkError}
            </h3>
            <CssTextField
              className={
                "mc-field-group wide-3 text-container mc-field-group-left"
              }
              required
              id="mce-NAME"
              name="name"
              label="Name"
              autoComplete="full-name"
              error={errors.name ? true : false}
              helperText={errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
            />
            <CssTextField
              className={
                "mc-field-group wide-3 text-container mc-field-group-right"
              }
              required
              type="email"
              id="mce-EMAIL"
              name="email"
              label="Email"
              autoComplete="email"
              error={errors.email ? true : false}
              helperText={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              variant="outlined"
            />
            <CssTextField
              className={
                "mc-field-group wide-3 text-container mc-field-group-right mc-field-special"
              }
              required
              type="text"
              id="mce-SPECIAL"
              name="special"
              label="Special"
              autoComplete="special"
              variant="outlined"
            />
            <CssTextField
              className={
                "mc-field-group wide-3 text-container mc-field-group-left"
              }
              id="mce-MESSAGE"
              name="message"
              label="Message"
              multiline
              onChange={handleChange}
              rows="5"
              variant="outlined"
            />
            <div className="wide-3" style={{ paddingLeft: 7 }}>
              <FormControlLabel
                control={
                  <CustomCheckbox
                    checked={state.checked}
                    onChange={handleChange}
                    name="checked"
                    color="primary"
                    className="custom-checkbox"
                  />
                }
                label="I would like to receive electronic communications from nexablu"
              />
              <CustomButton
                style={{ marginLeft: 20, marginTop: 72 }}
                color="white"
                width="142px"
                onClick={() => submitForm()}
                disabled={
                  errors.email || errors.name || !(state.name && state.email)
                    ? true
                    : false
                }
              >
                SEND
              </CustomButton>
              <script
                type="text/javascript"
                src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
              ></script>{" "}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const CssTextField = withStyles({
  root: {
    borderRadius: 0,
    "& label.Mui-focused": {
      color: "#eeeff6",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#eeeff6",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#eeeff6",
        borderRadius: 0,
      },
      "&:hover fieldset": {
        borderColor: "#eeeff6",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#eeeff6",
      },
    },
  },
})(TextField);

export default EmailForm;
