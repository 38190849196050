import React from "react";
import "../index.css";
import CustomButton from "./CustomButton";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  xLargeText: {
    fontWeight: 100,
    letterSpacing: ".05em",
    fontSize: 45,
  },
  subTop: {
    fontWeight: 300,
    fontSize: 22,
    margin: 0,
    marginTop: 10,
  },
  subMiddle: {
    fontWeight: 300,
    color: "#eeeff6",
    fontSize: 19,
    lineHeight: 1.8,
  },
  subMiddleBlack: {
    fontWeight: 300,
    lineHeight: 1.6,
    fontSize: 19,
    margin: "15px 0px",
  },
  sideMiddle: {
    color: "#eeeff6",
    fontSize: 30,
    margin: 0,
  },
});

function Main(props) {
  const history = useHistory();
  const {
    xLargeText,
    subTop,
    subMiddle,
    subMiddleBlack,
    sideMiddle,
  } = useStyles();

  return (
    <>
      <div
        className="bgimg display-container text-white padded"
        style={{
          backgroundImage:
            'url("' + require("../assets/images/Image-Banner-Home.jpg") + '")',
        }}
      >
        <div className="maxed">
          <div className="display-middle-left animate-opacity">
            <h1 className={xLargeText} style={{ marginBottom: 5 }}>
              Get more than IT
            </h1>
            <h1
              className={xLargeText}
              style={{ marginTop: 5, marginBottom: 60 }}
            >
              to grow your IP
            </h1>
            <div style={{ fontFamily: "Source Sans Pro" }}>
              <h2 className={subTop}>Forward-looking partnership model,</h2>
              <h2 className={subTop}>Super-agile development approach and</h2>
              <h2
                className={subTop}
                style={{
                  marginBottom: 60,
                }}
              >
                High-value, comprehensive nearshoring services.
              </h2>
            </div>
            <div className="display-container">
              <CustomButton
                color="white"
                width="195px"
                onClick={props.onContact}
              >
                GET IN TOUCH
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
      <div className="display-x">
        <img src={require("../assets/images/Picto-X.svg")} alt="large X" />
      </div>
      <div className="padded announcement-section">
        <div className="maxed">
          <div className="row-4 wide-3">
            <h2
              style={{
                fontWeight: 300,
                fontFamily: "Source Sans Pro",
                lineHeight: 1.8,
                fontSize: 22,
                color: "#267ABF",
              }}
            >
              Every coding challenge is an opportunity to drive growth further.
              That’s how we view our role. More than develop software, we
              develop long-term business partnerships that breed success.
            </h2>
          </div>
        </div>
      </div>
      <div
        className="padded announcement-section"
        style={{ backgroundColor: "#267abf" }}
      >
        <div className="maxed-flex">
          <div className="wide-4">
            <h1
              style={{
                color: "#eeeff6",
                fontSize: 45,
                marginBottom: 65,
                marginTop: 18,
              }}
            >
              Scale up quickly and effectively
            </h1>
            <div
              style={{
                fontFamily: "Source Sans Pro",
              }}
            >
              <h4 className={subMiddle}>
                We share your passion to dream big and take your ideas to the
                finish line. More than develop software, we develop
                collaborative environments that create momentum, boost
                productivity and achieve your goals.
              </h4>
              <h4 className={subMiddle}>
                When taking your startup or innovative project to the next
                level, we bring the tools, the teamwork and the vision to
                generate advanced, deployment-ready digital solutions that take
                full advantage of back-end cloud computing.
              </h4>
              <h4 className={subMiddle}>
                Our R3 approach gives you the agility and commitment you need,
                including embedded remote teams and access to proven frameworks,
                leading to faster delivery, quicker iterations and more
                effective progress.
              </h4>
            </div>
            <CustomButton
              color="white"
              width="290px"
              onClick={() => {
                props.onChange();
                history.push("/services");
              }}
            >
              DISCOVER OUR SERVICES
            </CustomButton>
          </div>
          <div className="wide-2" style={{ lineHeight: 1.3 }}>
            <img
              src={require("../assets/images/Picto-Cloud.svg")}
              alt="Cloud"
              style={{ width: 130 }}
            />
            <h2
              className={sideMiddle}
              style={{
                marginTop: 40,
              }}
            >
              Ours R3 Development Approach is
            </h2>
            <h2 className={sideMiddle}>Rapid, Responsive and</h2>
            <h2 className={sideMiddle}>Results-driven</h2>
          </div>
        </div>
      </div>
      <div className="padded announcement-section">
        <div className="maxed-flex">
          <div className="wide-2">
            <img
              src={require("../assets/images/Image-Ville.png")}
              alt="Town"
              style={{ width: 339 }}
            />
          </div>
          <div className="wide-4">
            <h1
              style={{
                fontSize: 45,
                color: "#267ABF",
                marginBottom: 50,
                marginTop: 0,
              }}
            >
              Leverage nearshore support
            </h1>
            <div
              style={{
                fontFamily: "Source Sans Pro",
              }}
            >
              <h4 className={subMiddleBlack}>
                Willing to go to the ends of the Earth for results?
                Cost-effective outsourcing excellence is closer than you think.
                More than develop software, we develop all the nearshoring
                reliability you need, ah?!
              </h4>
              <h4 className={subMiddleBlack}>
                Based in Canada, nexablu draws from a pool of highly skilled
                senior developers mainly from the Montreal area, a
                world-renowned hub for digital talent in various innovative
                industries.
              </h4>
              <h4 className={subMiddleBlack}>
                Our full-service software development offering is tailored to
                the needs of technology startups and spinoffs, including
                specialized teams, result-oriented solutions, affordable
                services, shared values and common time zones.
              </h4>
              <CustomButton
                color="#267ABF"
                width="290px"
                onClick={() => {
                  props.onChange();
                  history.push("/about");
                }}
              >
                LEARN MORE ABOUT US
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
      <div
        className="padded announcement-section"
        style={{ backgroundColor: "#267abf" }}
      >
        <div className="maxed-flex">
          <div className="wide-4">
            <h1
              style={{
                color: "#eeeff6",
                fontSize: 45,
                marginTop: 10,
                marginBottom: 50,
              }}
            >
              Outsource with confidence
            </h1>

            <div
              style={{
                fontFamily: "Source Sans Pro",
              }}
            >
              <h4 className={subMiddle}>
                As a forward-looking partner, we have the breadth of expertise
                to take charge of all your software development requirements.
                But we also understand your need for added value and
                cost-effective outcomes.
              </h4>
              <h4 className={subMiddle}>
                Consider us as your all-in-one business-savvy, software design
                and development profit center down the hall, with the industry
                knowledge and capabilities you need to meet your objectives.
              </h4>
            </div>
            <CustomButton color="white" width="325px" onClick={props.onContact}>
              TELL US ABOUT YOUR PROJECT
            </CustomButton>
          </div>
          <div className="wide-2">
            <img
              src={require("../assets/images/Picto-Hand.svg")}
              alt="Hand"
              style={{ width: 113, marginBottom: 30 }}
            />
            <h2 className={sideMiddle} style={{ margin: "3px 0px" }}>
              Drive engagement,
            </h2>
            <h2 className={sideMiddle} style={{ margin: "3px 0px" }}>
              Develop ideas and
            </h2>
            <h2 className={sideMiddle} style={{ margin: "3px 0px" }}>
              Deploy change
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
