import React from "react";
import "../index.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  middleText: {
    fontWeight: 300,
    color: "black",
    fontSize: 19,
    lineHeight: 1.9,
  },
});

function About(props) {
  const { middleText } = useStyles();

  return (
    <>
      <div
        className="bgimg display-container text-white padded"
        style={{
          backgroundImage:
            'url("' + require("../assets/images/Image-Banner-About.jpg") + '")',
        }}
      ></div>
      <div className="padded announcement-section">
        <div className="maxed-flex">
          <div className="wide-4">
            <h1
              style={{
                fontSize: 45,
                color: "#267ABF",
                marginBottom: 64,
                marginTop: 0,
              }}
            >
              We are nexablu
            </h1>

            <div
              style={{
                fontFamily: "Source Sans Pro",
              }}
            >
              <h2 className={middleText}>
                Founded by senior developers with a solid business background
                and years of combined marketing, financial and software
                development expertise, nexablu works harder and smarter to bring
                your ideas to fruition.
              </h2>
              <h2 className={middleText}>
                We take pride in contributing to our clients’ growth. And we do
                what it takes to meet that commitment. At nexablu, you will find
                loyalty, dedication and drive in perfect alignment with your
                pursuits.
              </h2>
              <h2 className={middleText}>
                For us, nothing is more rewarding than seeing our clients
                succeed, knowing that targets were met through our efforts,
                whether in terms of key deliverables, activation results or
                increased value for investors.
              </h2>
              <h2 className={middleText}>
                More than develop software, we develop a shared sense of purpose
                focused on achieving your goals.
              </h2>
            </div>
          </div>
          <div className="wide-2">
            <img
              src={require("../assets/images/Picto-X.svg")}
              alt="large X"
              style={{ width: 89, marginBottom: 2 }}
            />
            <h2 style={{ color: "#267ABF", fontSize: 30 }}>
              We are a nexus of business insights, software development
              capabilities, super-agile methodology and Canadian dependability,
              at your service.
            </h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
