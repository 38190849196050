import React from "react";
import "../index.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  middleText: {
    fontWeight: 300,
    color: "black",
    fontSize: 19,
    lineHeight: 1.9,
    marginBottom: 20,
  },
  rowTitle: {
    color: "#eeeff6",
    marginTop: 40,
    marginBottom: 25,
  },
  rowTitleBlue: {
    color: "#267ABF",
    marginTop: 40,
    marginBottom: 25,
  },
  rowText: {
    fontWeight: 300,
    color: "#eeeff6",
    lineHeight: 2,
    fontFamily: "Source Sans Pro",
  },
  rowTextBlack: {
    fontWeight: 300,
    lineHeight: 2,
    fontFamily: "Source Sans Pro",
  },
});

function Services(props) {
  const {
    middleText,
    rowTitle,
    rowTitleBlue,
    rowText,
    rowTextBlack,
  } = useStyles();

  return (
    <>
      <div
        className="bgimg display-container text-white padded"
        style={{
          backgroundImage:
            'url("' +
            require("../assets/images/Image-Banner-Service.jpg") +
            '")',
        }}
      ></div>
      <div className="padded announcement-section">
        <div className="maxed-flex">
          <div className="wide-4">
            <h1
              style={{
                fontSize: 45,
                color: "#267ABF",
                marginBottom: 50,
                marginTop: 40,
              }}
            >
              Software Solution Outsourcing
            </h1>

            <div
              style={{
                fontFamily: "Source Sans Pro",
              }}
            >
              <h2 className={middleText}>
                No matter where you are in your innovation process, nexablu
                brings together the experts you need to overcome your challenges
                and meet your objectives.
              </h2>
              <h2 className={middleText}>
                You may need a bit of nexablu. Or all of nexablu. Either way,
                you get pragmatic, cost-effective nearshoring solutions through
                our R3 development approach.
              </h2>
              <h2 className={middleText}>
                Choose from core software services
                (architecture/development/deployment) as well as a range of
                complementary capabilities designed to add value to your
                project.
              </h2>
            </div>
          </div>
          <div className="wide-2">
            <img
              src={require("../assets/images/Picto-Canada.svg")}
              alt="large X"
              style={{ width: 130, paddingBottom: 16 }}
            />
            <h2 style={{ color: "#267ABF", fontSize: 30 }}>
              We breathe life into your vision by giving it shape, substance and
              style… in terms of software development and beyond.
            </h2>
          </div>
        </div>
      </div>

      <div
        className="padded announcement-section"
        style={{ backgroundColor: "#267abf" }}
      >
        <div className="maxed">
          <h1
            style={{
              color: "#eeeff6",
              marginBottom: 67,
              fontSize: 45,
              paddingLeft: 27,
            }}
          >
            Core Software Services
          </h1>
          <div className="maxed-flex">
            <div className="wide-2" style={{ paddingBottom: 12 }}>
              <img
                src={require("../assets/images/Picto-Service-Architecture.svg")}
                alt="Architecture icon"
              />
              <h2 className={rowTitle}>Architecture</h2>
              <h4 className={rowText}>
                Getting the system structure right is critical to the success of
                your enterprise. We take care of all the infrastructure
                fundamentals and software architecture, creating a blueprint for
                the application and its ongoing development and deployment.
              </h4>
            </div>
            <div className="wide-2" style={{ paddingBottom: 12 }}>
              <img
                src={require("../assets/images/Picto-Service-Development.svg")}
                alt="Development icon"
              />
              <h2 className={rowTitle}>Development</h2>
              <h4 className={rowText}>
                Once system parameters are established, we get the job done. We
                configure the required infrastructure and build robust source
                code to power your application. Our superior agility enables
                cost-effective implementations and provides reliability and
                speed when scaling solutions.
              </h4>
            </div>
            <div className="wide-2" style={{ paddingBottom: 12 }}>
              <img
                src={require("../assets/images/Picto-Service-Deployment.svg")}
                alt="Architecture icon"
              />
              <h2 className={rowTitle}>Deployment</h2>
              <h4 className={rowText}>
                Our rigorous process ensures scaling success regardless of
                customizing requirements or computing environments. By
                engineering multiple iterations with real-life interactions, we
                increase the quality of results and seek to improve the product
                at every step.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="padded announcement-section">
        <div className="maxed">
          <h1
            style={{
              marginBottom: 67,
              fontSize: 45,
              color: "#267ABF",
              paddingLeft: 27,
            }}
          >
            Complementary Capabilities
          </h1>
          <div className="maxed-flex">
            <div className="wide-2" style={{ paddingBottom: 12 }}>
              <img
                src={require("../assets/images/Picto-Service-Analysis.svg")}
                alt="Analysis icon"
              />
              <h2 className={rowTitleBlue}>Business Analysis</h2>
              <h4 className={rowTextBlack}>
                Enhance decision-making and product-building with nexablu. We
                are developers with extensive business experience, so we are
                naturally drawn to creating value. We challenge your strategies
                to help you make the most of your innovation.
              </h4>
            </div>
            <div className="wide-2" style={{ paddingBottom: 12 }}>
              <img
                src={require("../assets/images/Picto-Service-Design.svg")}
                alt="Design icon"
              />
              <h2 className={rowTitleBlue}>UX/UI Design</h2>
              <h4 className={rowTextBlack}>
                Looking to define or refine the user experience? Our UX
                designers are keen to identify and solve user problems, and
                create a deeper connection with your product or service. When
                time comes to develop intuitive, appealing interfaces, our UI
                designers step in to create the look and feel of the digital
                experience.
              </h4>
            </div>
            <div className="wide-2" style={{ paddingBottom: 12 }}>
              <img
                src={require("../assets/images/Picto-Service-Brand.svg")}
                alt="Brand icon"
              />
              <h2 className={rowTitleBlue}>Brand positioning</h2>
              <h4 className={rowTextBlack}>
                Need to tell the world about your innovation? Want to drive
                engagement, investment and growth? Tap into nexablu’s network of
                branding, creative content and copywriting resources.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
